import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { MaLevel } from 'src/app/models/site.model';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  successNotification(path: string) {
    Swal.fire({
      text: 'Your product has been saved',
      icon: 'success',
    }).then((result: any) => {
      this.router.navigate([`/${path}`]);
    });
  }

  errorNotification(path: string) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your product hasn\'t been saved',
    }).then((result: any) => {
      this.router.navigate([`/${path}`]);
    });
  }

  successNotificationM(path: string) {
    Swal.fire({
      text: 'Your module has been saved',
      icon: 'success',
    }).then((result: any) => {
      location.reload()
      this.router.navigate([`/${path}`]);
    });
  }

  errorNotificationM(path: string) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your module hasn\'t been saved',
    }).then((result: any) => {
      location.reload()
      this.router.navigate([`/${path}`]);
    });
  }

  successDelete() {
    Swal.fire({
      icon: 'success',
      title: 'deleted',
      text: 'Your product has been deleted',
    })
  }

  errorDelete() {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your product hasn\'t  been deleted',
    })
  }

  getProductById(id: string) {
    return this.afs.doc<Product>(`product/` + id).valueChanges();
  }

  getProductList() {
    return this.afs.collection('product', (ref) => ref
      .orderBy('name', 'asc'));
  }

  getProductByNameSort(keyword: string) {
    return this.afs.collection('product', (ref) => ref
      .where('keyword', 'array-contains', keyword)
    );
  }

  getModule() {
    return this.afs.collection('module');
  }

  getMaLevel() {
    return this.afs.collection('maintenanceLevel');
  }

  getMaLevelById(id: string) {
    return this.afs.doc<MaLevel>(`maintenanceLevel/` + id).valueChanges();
  }

  async addProduct(product: Product) {
    try {
      const keyword = await this.generateKeyword(product.name);
      (await this.afs.collection('product').add({
        name: product.name,
        active: product.active,
        keyword
      }).then(async (res) => {
        await this.afs.collection('product').doc(res.id).update({
          id: res.id
        })
        this.successNotification('product')
      }))
    } catch (error) {
      this.errorNotification('add-product')
    }
  }

  async updateProduct(id: string, product: Product) {
    try {
      const keyword = await this.generateKeyword(product.name);
      (await this.afs.collection('product').doc(id).update({
        name: product.name,
        active: product.active,
        keyword
      }).then(() => {
        this.successNotification('product')
      }))
    } catch (error) {
      this.errorNotification('edit-product')
    }
  }

  async updateModule(id, moduleNew:any) {
    try {
      // const keyword = await this.generateKeyword(product.name);
      (await this.afs.collection('module').doc(id).update({
        module: moduleNew
      }).then(() => {
        this.successNotificationM('module')
      }))
    } catch (error) {
      this.errorNotificationM('module')
    }
  }

  async deleteProductById(id: any) {
    try {
      await this.afs.collection('product').doc(id).delete();
      this.successDelete()
    } catch (err) {
      this.errorDelete()
    }
  }

  private async generateKeyword(name: string) {
    function creatKeywords(str: string) {
      const arrName = [];
      let temp = "";
      for(var i=0;i< str.length; i++) {
        if(str[i] == " ") {
          temp = "";
        } else {
          temp = temp + str[i];
          arrName.push(temp);
        }
      }
      return arrName
    }
    const keywordLowerCase = await creatKeywords(name.toLowerCase())
    const keywordUpperCase = await creatKeywords(name.toUpperCase())
    return [
      '',
      ...keywordLowerCase,
      ...keywordUpperCase
    ]
  }

}
