import { EventService } from './../../services/event/event.service';
import { Event } from './../../models/event.model';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eventsite',
  templateUrl: './eventsite.component.html',
  styleUrls: ['./eventsite.component.css']
})
export class EventsiteComponent implements OnInit {
  p = 1;
  Event: Event[];
  hideWhenNoStaff = false;
  noData = false;
  preLoader = true;
  searchValue = '';

  constructor(
    private eventService: EventService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.dataState();
    this.getEvent()
  }

  getEvent() {
    this.eventService.getEventList().snapshotChanges().subscribe(data => {
      this.Event = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.Event.push(item as Event)
      })
    });
  }

  dataState() {
    this.eventService.getEventList().snapshotChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStaff = false;
        this.noData = true;
      } else {
        this.hideWhenNoStaff = true;
        this.noData = false;
      }
    });
  }

  search() {
    if (this.searchValue !== undefined && this.searchValue !== null && this.searchValue !== '') {
      this.getEventByNameSort(this.searchValue)
      this.dataStateSearch(this.searchValue)
    } else {
      this.dataState();
      this.getEvent()
    }
  }

  dataStateSearch(searchValue: string) {
    this.eventService.getEventByNameSort(searchValue).snapshotChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoStaff = false;
        this.noData = true;
      } else {
        this.hideWhenNoStaff = true;
        this.noData = false;
      }
    });
  }

  getEventByNameSort(searchValue: any) {
    this.eventService.getEventByNameSort(searchValue).snapshotChanges().subscribe(data => {
      this.Event = [];
      data.map(items => {
        const item = items.payload.doc.data();
        item['$uid'] = items.payload.doc['id'];
        this.Event.push(item as Event)
      })
    });
  }

 
  alertDeleteEvent(event: any) {
    Swal.fire({
      title: 'Do you want to delete Event.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2ED0B9',
      cancelButtonColor: '#9C9FA6',
      confirmButtonText: 'Yes, I do'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        this.eventService.deleteEventById(event.$uid)
      }
    })
  }

}
