import { Component, OnInit } from '@angular/core';
import { User } from 'firebase';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
import { TicketService } from 'src/app/services/ticket/ticket.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-external-layout',
  templateUrl: './external-layout.component.html',
  styleUrls: ['./external-layout.component.css']
})
export class ExternalLayoutComponent implements OnInit {
  User: User;
  user
  user$: any
  AuthService: any;
  activeState: any;
  menu: any;
  redirectPath: string
  // username = localStorage.getItem('username');
  sitename = localStorage.getItem('sitename');
  
  
  constructor(
    public auth: AuthService,
    public userService: UserService,
    public ticketService: TicketService,
    public dataService: DataService
  ) { }

  ngOnInit() {
    // let username = localStorage.getItem('username');
    let sitename = localStorage.getItem('sitename');
   // console.log(username);
   // console.log(sitename);
    // this.auth.user$.subscribe(user => this.user = user )
    // console.log(this.user)
    // this.dataService.currentRedirect.subscribe(redirectPath => this.redirectPath = redirectPath)
    // console.log(this.redirectPath)
    // this.User = this.auth.authState;
    // console.log(this.User)
    // if (this.User != undefined) {
    //   this.getUserValue();
    //   this.setMenu(this.redirectPath)
    // }

    // if (this.User == undefined) {
  
    //  console.log("ไม่ได้ LOgin")
    // }

  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('toggled');
  }

  getUserValue() {
    this.userService.getUserById(this.User.uid).snapshotChanges().subscribe(data => {
      this.user$ = data.payload.data() as User;
      console.log(this.user$);
    })
  }

  setMenuState(menu: any) {
    this.activeState = menu;
  }

  setMenu(menu: any) {
    this.setMenuState(menu)
    this.menu = menu
  }

  newPath() {
    if (this.user.roles.customer === true) {
      this.dataService.changeRedirectSource('appsite-ticket')
    } else if (this.user.roles.supporter === true) {
      this.dataService.changeRedirectSource('ticket')
    }
  }

}