import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Event } from 'src/app/models/event.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EventService {


  constructor(
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  successNotification(path: string) {
    Swal.fire({
      text: 'Your Event has been saved',
      icon: 'success',
    }).then((result: any) => {
      this.router.navigate([`/${path}`]);
    });
  }

  errorNotification(path: string) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your Event hasn\'t been saved',
    }).then((result: any) => {
      this.router.navigate([`/${path}`]);
    });
  }


  successDelete() {
    Swal.fire({
      icon: 'success',
      title: 'deleted',
      text: 'Your Event has been deleted',
    })
  }

  errorDelete() {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'Your Event hasn\'t  been deleted',
    })
  }

  getEventById(id: string) {
    return this.afs.doc<Event>(`event/` + id).valueChanges();
  }

  getEventList() {
    return this.afs.collection('event', (ref) => ref
    .orderBy('date.singleDate.jsDate', 'desc'))
  }

  getEventByNameSort(keyword: string) {
    return this.afs.collection('event', (ref) => ref
      .where('keyword', 'array-contains', keyword)
    );
  }


  async addEvent(event: Event) {
    try {
      const keyword = await this.generateKeyword(event.event);
      (await this.afs.collection('event').add({
        site: event.site,
        date: event.date,
        timeStart: event.timeStart,
        timeEnd: event.timeEnd,
        event: event.event,
        keyword
      }).then(async (res) => {
        await this.afs.collection('event').doc(res.id).update({
          id: res.id
        })
        this.successNotification('eventsite')
      }))
    } catch (error) {
      this.errorNotification('add-event')
    }
  }

  // async updateEvent(id: string, event: Event) {
  //   console.log(id);
  //   console.log(event);
  //   try {
  //     const keyword = await this.generateKeyword(event.event);
  //     (await this.afs.collection('event').doc(id).update({
  //       site: event.site,
  //       date: event.date,
  //       timeStart: event.timeStart,
  //       timeEnd: event.timeEnd,
  //       event: event.event,
  //       keyword
  //     }).then(() => {
  //       this.successNotification('eventsite')
  //     }))
  //   } catch (error) {
  //     this.errorNotification('edit-event')
  //   }
  // }

  async updateEvent(event: Event, id: any) {
    //console.log(event);
    try {
      const keyword = await this.generateKeyword(
        event.event)
      await this.afs.collection('event').doc(id).update({
        site: event.site,
        date: event.date,
        id: id,
        timeStart: event.timeStart,
        timeEnd: event.timeEnd,
        event: event.event,
        keyword
      })
      await this.successNotification('eventsite');
    } catch (error) {
     // console.log(error)
      this.errorNotification('eventsite');
    }
  }


  async deleteEventById(id: any) {
    try {
      await this.afs.collection('event').doc(id).delete();
      this.successDelete()
    } catch (err) {
      this.errorDelete()
    }
  }

  private async generateKeyword(name: string) {
    function creatKeywords(str: string) {
      const arrName = [];
      let temp = "";
      for(var i=0;i< str.length; i++) {
        if(str[i] == " ") {
          temp = "";
        } else {
          temp = temp + str[i];
          arrName.push(temp);
        }
      }
      return arrName
    }

    const keywordEvent = await creatKeywords(`${name}`)
    return [
      '',
      ...keywordEvent,
    ]
  }

}
